// import Vuex from 'vuex';
// import Vue from 'vue';

// Vue.use(Vuex)
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';import 'firebase/firestore'
import { firestoreAction } from 'vuexfire';

export default {
  namespaced: true,

  state: {
    time:0,
    autoplay:false,
    image2:'',
    image3:'',
    image4:'',
    image5:'',
    image6:'',
    image7:'',
    image8:'',
    image9:'',
    image10:'',
    image11:'',
    image12:'',
    chatId:''
    // menuCollapsed: false,
    // isMobile: false,
    // tab: 'about'
  },

  getters: {
    autoplay: (state) => state.autoplay,
    time:(state) => state.time,
    image2: (state) => state.image2,
    image3:(state) => state.image3,
    image4:(state) => state.image4,
    image5:(state) => state.image5,
    image6:(state) => state.image6,
    image7:(state) => state.image7,
    image8:(state) =>state.image8,
    image9:(state) => state.image9,
    image10:(state) => state.image10,
    image11:(state) => state.image11,
    image12:(state) => state.image12,
    chatId:(state) => state.chatId
  },

  mutations: {
    SET_AUTOPLAY (state, payload) {
      state.autoplay = payload
    },
    SET_TIME( state,payload){
      state.time = payload
    },
  SET_IMAGE2(state,payload) {
      state.image2  = payload
  },
  SET_IMAGE3(state,payload) {
    state.image3  = payload
},
SET_IMAGE4(state,payload) {
  state.image4  = payload
},
SET_IMAGE5(state,payload) {
  state.image5  = payload
},
SET_IMAGE6(state,payload) {
  state.image6  = payload
},
SET_IMAGE7(state,payload) {
state.image7  = payload
},
SET_IMAGE8(state,payload) {
state.image8  = payload
},
SET_IMAGE9(state,payload) {
state.image9  = payload
},
SET_IMAGE10(state,payload) {
  state.image10  = payload
},
SET_IMAGE11(state,payload) {
state.image11  = payload
},
SET_IMAGE12(state,payload) {
state.image12  = payload
},
SET_CHAT_ID(state,payload){
  state.chatId=payload
}
  },

  actions: {
    setAutoplay ({ commit }, payload) {
      commit('SET_AUTOPLAY', payload)
    },
    setTime({commit},payload){
      commit('SET_TIME',payload)
    },
    setImage2({commit},payload){
      commit('SET_IMAGE2',payload)
    },
    setImage3({commit},payload){
      commit('SET_IMAGE3',payload)
    },
    setImage4({commit},payload){
      commit('SET_IMAGE4',payload)
    },
    setImage5({commit},payload){
      commit('SET_IMAGE5',payload)
    },
    setImage6({commit},payload){
      commit('SET_IMAGE6',payload)
    },
    setImage7({commit},payload){
      commit('SET_IMAGE7',payload)
    },
    setImage8({commit},payload){
      commit('SET_IMAGE8',payload)
    },
    setImage9({commit},payload){
      commit('SET_IMAGE9',payload)
    },
    setImage10({commit},payload){
      commit('SET_IMAGE10',payload)
    },
    setImage11({commit},payload){
      commit('SET_IMAGE11',payload)
    },
    setImage12({commit},payload){
      commit('SET_IMAGE12',payload)
    },
  loading({commit}){
      console.log("loading")
      // commit('SET_IMAGE2','')
      // commit('SET_IMAGE3','')
      // commit('SET_IMAGE4','')
      // commit('SET_IMAGE5','')
      // commit('SET_IMAGE6','')
      // commit('SET_IMAGE7','')
      // commit('SET_IMAGE8','')
      // commit('SET_IMAGE9','')
      // commit('SET_IMAGE10','')
      // commit('SET_IMAGE11','')
      // commit('SET_IMAGE12','')


      commit('SET_AUTOPLAY',5000)
      setInterval(() => {
        commit('SET_IMAGE2','/images/mobile2.png')
      },1000)
      setInterval(() => {
        commit('SET_IMAGE3','/images/mobile3.png')
      },2000)
      setInterval(() => {
        commit('SET_IMAGE4','/images/mobile4.png')
      },3000)
      setInterval(() => {
        commit('SET_IMAGE5','/images/mobile5.png')
      },4000)
      setInterval(() => {
        commit('SET_IMAGE6','/images/mobile6.png')
      },5000)
      setInterval(() => {
        commit('SET_IMAGE7','/images/mobile7.png')
      },6000)
      setInterval(() => {
        commit('SET_IMAGE8','/images/mobile8.png')
      },7000)
      setInterval(() => {
        commit('SET_IMAGE9','/images/mobile9.png')
      },8000)
      setInterval(() => {
        commit('SET_IMAGE10','/images/mobile10.png')
      },9000)
      setInterval(() => {
        commit('SET_IMAGE11','/images/mobile11.png')
      },10000)
      setInterval(() => {
        commit('SET_IMAGE12','/images/mobile12.png')
      },11000)
  
  },
  doSubmitContactForm: firestoreAction ( ({rootGetters},data) => {
    var id = firebase.firestore().collection('ids').doc().id
    console.log(rootGetters['auth/currentUser']);
    console.log(data);
    var obj = {
        id: id,
        name: data.name,
        email:data.email,
        phoneNumber: data.phoneNumber,
        message: data.message,
        createdAt: firebase.firestore.Timestamp.now(),
       }
       firebase.firestore().collection('contactUs').doc(id).set({
       ...obj
      })
      console.log(data.email);
      if(data.language=='ar'){
        firebase.firestore().collection('mail').doc(id).set({
          to:data.email,
          // message:{
          //   html: "you contacted us",
          //   subject: "Contact email"
          // }
          template:{
            name: 'contactEmailToClient-ar'
          }
         })
      }
      else{
        firebase.firestore().collection('mail').doc(id).set({
          to:data.email,
          // message:{
          //   html: "you contacted us",
          //   subject: "Contact email"
          // }
          template:{
            name: 'contactEmailToClient'
          }
         })
      }
      
       id = firebase.firestore().collection('ids').doc().id
       firebase.firestore().collection('mail').doc(id).set({
        to:'info@israagroup.com',
        // message:{
        //   html: "you contacted us",
        //   subject: "Contact email"
        // }
        template:{
          name: 'contactEmailToIsraa',
          data:{
            name: data.name,
            phoneNumber: data.phoneNumber,
            email: data.email,
            message: data.message
    
          }
        }
       })
}),

  }
}