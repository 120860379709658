//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from "vuex";
export default {
  name: "HeaderLogo",
  props: ["logoColor"],
  computed: {
    currentPage() {
      return this.$route.path;
    },
    ...mapGetters({
      showMenuModal: "layout/showMenuModal",
    }),
  },
  methods: {
    ...mapActions({
      closeMenuModal: "layout/closeMenuModal",
    }),
    closeModals() {
      if (this.currentPage === "/") {
        if (this.showMenuModal) {
          this.closeMenuModal();
        }
      } else {
        return;
      }
    },
  },
};
