//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HeaderLogo from "@/modules/layout/components/header-logo.vue";
import MenuModalButton from "@/modules/layout/components/menu-modal-button.vue";

export default {
  name: "app-header",
  props: ["logoColor"],
  components: {
    HeaderLogo,
    MenuModalButton,
  },
};
