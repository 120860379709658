var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"menu-primary"},[_c('ul',{staticClass:"nav"},[_c('li',{class:[_vm.currentPage === '/' ? _vm.activeClass : '', 'nav-item'],on:{"click":_vm.closeOnSameRoute}},[_c('h2',{staticClass:"cursor-pointer header-item",on:{"click":function($event){_vm.toggleMenuModal();
          _vm.handleScroll('page-title');}}},[_c('app-i18n',{attrs:{"code":"header.home"}})],1)]),_c('li',{class:[
        _vm.currentPage.includes('projects') ||
        _vm.currentPage.includes('projects-inside')
          ? _vm.activeClass
          : '',
        'nav-item' ]},[_c('h2',{staticClass:"cursor-pointer header-item",on:{"click":function($event){_vm.toggleMenuModal();
          _vm.handleScroll('about-us');}}},[_c('app-i18n',{attrs:{"code":"header.aboutUs"}})],1)]),_c('li',{class:[
        _vm.currentPage.includes('projects') ||
        _vm.currentPage.includes('projects-inside')
          ? _vm.activeClass
          : '',
        'nav-item' ]},[_c('h2',{staticClass:"cursor-pointer header-item",on:{"click":function($event){_vm.toggleMenuModal();
          _vm.handleScroll('projects');}}},[_c('app-i18n',{attrs:{"code":"header.products"}})],1)]),_c('li',{class:[
        _vm.currentPage.includes('contacts') ? _vm.activeClass : '',
        'nav-item' ]},[_c('h2',{staticClass:"cursor-pointer header-item",on:{"click":function($event){_vm.toggleMenuModal();
          _vm.handleScroll('contact-us');}}},[_c('app-i18n',{attrs:{"code":"header.contactUs"}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }