//
//
//
//
//
//
//
//

export default {
  name: 'app-view-item-custom',

  props: ['label', 'value'],

  computed: {
    isBlank() {
      return (
        (!this.value &&
          this.value !== 0 &&
          this.value !== false) ||
        (Array.isArray(this.value) && !this.value.length)
      );
    },
  },
};
