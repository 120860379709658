const LayoutPage = () => import('@/modules/layout/components/layout.vue');
const HomePage = () =>  import('@/modules/home/components/home-page.vue');
import {i18n} from '@/vueI18n'
export default [
  {
    path: '/:lang?',
    meta:{unauth: true},
    component: LayoutPage,
    children: [
      {
        name: 'home',
        path: '',
        component: HomePage,
        meta: { unauth: true },
        beforeEnter: (to, from, next) => {
          document.title = i18n('app.title') + " | " +  i18n('app.home')
          next()
        }
      },
    
    ],  
  },
  
];
