//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from "vuex";
import { scroll } from "quasar";
const { getScrollTarget, setScrollPosition } = scroll;

export default {
  name: "ModalMenuPrimary",
  data() {
    return {
      activeClass: "current-nav-item",
    };
  },
  methods: {
    closeOnSameRoute: function () {
      // const clickTargetHref = event.target.getAttribute( 'href' );
      this.toggleMenuModal();
      this.variablesNull();
    },
    ...mapActions({
      toggleMenuModal: "layout/toggleMenuModal",
      variablesNull: "layout/variablesNull",
    }),
    handleScroll(eleId) {
      const ele = document.getElementById(eleId); // You need to get your element here
      const target = getScrollTarget(ele);
      var offset;
      if (eleId == "page-title") {
        offset = 0;
      } else {
        offset = ele.offsetTop + 150;
      }
      const duration = 500;
      setScrollPosition(target, offset, duration);
    },
  },
  computed: {
    currentPage() {
      return this.$route.path;
    },
  },
};
