import store from '@/modules/auth/auth-store';
import authGuardMixin from '@/modules/auth/guards/auth-guard-mixin';
import unauthGuardMixin from '@/modules/auth/guards/unauth-guard-mixin';

const mixins = [
  authGuardMixin,
  unauthGuardMixin,
];

export default {
  store,
  mixins,
};
