import { i18n } from '@/vueI18n';
import ProjectViewPage from '@/modules/product/components/product-view-page.vue';

export default [
  {
    name: 'project',
    path: '/:lang?/product/:id',
    component: ProjectViewPage,
    beforeEnter: (to, from, next) => {
      document.title = i18n('app.title')
      next()
    }
  },
 
];
