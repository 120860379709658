//
//
//
//
//
//
//
//
//

import Header from "@/modules/layout/components/header.vue";
import Footer from "@/modules/layout/components/footer.vue";
// import i18n from "@/vueI18n";

export default {
  name: "app-layout",
  components: {
    [Header.name]: Header,
    [Footer.name]: Footer,
  },
  data() {
    return {};
  },
  // computed:{
  //   language(){
  //     return i18n.locale
  //   }
  // },
  // watch:{
  //   language(){
  //     document.title = this.i18n('app.title')
  //   }
  // },
  // mounted(){
  //   console.log('icons',this.$q.iconSet.field.error)
  //   this.$q.iconSet.field.error = ''
  // },
  methods: {
    // closePopup(e){
    //   // e.preventDefault()
    //   // console.log('evenet',e.keyCode)
    //   console.log('enter')

    // },
    // isValid () {
    //   return this.email.length <= 3
    // },
    i18n(key, args) {
      return this.$t(key, args);
    },
    openChat() {
      this.chat = !this.chat;
      this.chatOpened = !this.chatOpened;
      console.log("chat", this.chat);
    },
    isValidEmail(val) {
      const emailPattern =
        /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      //
      if (emailPattern.test(val)) {
        this.validEmail = true;
        return true;
      } else {
        this.validEmail = false;
        return this.i18n("chat.invalidEmailAddress");
      }
    },
    isValidPhone(val) {
      const phonePattern = /^[0-9]+$/;
      if (phonePattern.test(val)) {
        this.validPhone = true;
        return true;
      } else {
        this.validPhone = false;
        return this.i18n("chat.invalidPhoneNumber");
      }
    },
  },
};
