//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Fragment } from 'vue-fragment';

import MenuModal from "@/modules/layout/components/menu-modal.vue";

export default {
    name: 'app-footer',
    components: {
        Fragment,
        MenuModal,
    },
    computed: {
        currentPage() {
            return this.$route.path;
        }
    }
}
