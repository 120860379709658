//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'app-image-carousel',

  props: {
    value: Array,
  },
};
