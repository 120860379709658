const firebaseConfig = {
  apiKey: "AIzaSyD2xhVEJ8YPkRgi-GeSzGh5YPUTVIjCp24",
  authDomain: "mining-israa-group.firebaseapp.com",
  databaseURL: "https://mining-israa-group-default-rtdb.firebaseio.com",
  projectId: "mining-israa-group",
  storageBucket: "mining-israa-group.appspot.com",
  messagingSenderId: "278626203823",
  appId: "1:278626203823:web:02f3997c73bd307e7c3c5a",
  measurementId: "G-FE7L7HCK52"
};
// Cloud Functions
const backendUrl = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/api`;

// const backendUrl = `https://europe-west3-${
//   firebaseConfig.projectId
// }.cloudfunctions.net/api`;


// App Engine
// const backendUrl = `<insert app engine url here>`;

export default {
  firebaseConfig,
  backendUrl,
};
