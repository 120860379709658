import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client';
import firebaseInit from '@/shared/firebase/firebase-init';

export default class AuthService {
  static init() {
    return firebaseInit();
  }

  static onAuthStateChanged(
    callbackSuccess,
    callbackError,
  ) {
    return firebase
      .auth()
      .onAuthStateChanged(callbackSuccess, callbackError);
  }
  static async sendWelcomeEmail(email, name) {
    return this.sendWelcomeEmailFromBackend(email, name);
  }
  static async sendWelcomeEmailFromBackend(email, name) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation AUTH_SEND_WELCOME_EMAIL($email: String!, $name: String) {
          authSendWelcomeEmail(email: $email, name: $name)
        }
      `,
      variables: {
        email,
        name,
      },
    });

    const res = response.data.authSendWelcomeEmail
    return res;
  }

  static async fetchMe() {
    const response = await graphqlClient.query({
      query: gql`
        {
          authMe {
            id
            authenticationUid
            fullName
            firstName
            lastName
            phoneNumber
            email
            roles
            avatars {
              id
              name
              publicUrl
            }
          }
        }
      `,
    });

    return response.data.authMe;
  }

  static async isEmailConfigured() {
    const response = await graphqlClient.query({
      query: gql`
        {
          authIsEmailConfigured
        }
      `,
    });

    return response.data.authIsEmailConfigured;
  }


  static async reauthenticateWithStorageToken() {
    try {
      // debugger;
      let token = localStorage.getItem('token');
      if (!token) {
        const response = await graphqlClient.query({
          query: gql`
            {
              authStorageToken
            }
          `,
        });

        token = response.data.authStorageToken;
        localStorage.setItem(
          'token',
          this.encryptString(token, 'secret token'),
        );
      } else {
        token = this.decryptString(token, 'secret token');
      }

      return firebase.auth().signInWithCustomToken(token);
    } catch (error) {
      // console.error(error);
      // throw error
    }
  }
  static signout() {
    return firebase.auth().signOut();
  }


   //#region [ Encryption Functions ]
  // ================================================================= //
  //                          ENCRYPT OBJECT                           //
  // ================================================================= //
  static encryptObject(object, key) {
    const cryptoJSON = require('crypto-json');
    const algorithm = 'aes256';
    const encoding = 'hex';
    const password = key;
    let keys = [];

    for (let [k, value] of Object.entries(object)) {
      if (value) {
        keys.push(k);
      }
    }
    const output = cryptoJSON.encrypt(object, password, {
      encoding,
      keys,
      algorithm,
    });
    return output;
  }
  // ================================================================= //
  //                          DECRYPT OBJECT                           //
  // ================================================================= //
  static decryptObject(encryptedObject, key) {
    const cryptoJSON = require('crypto-json');
    const algorithm = 'aes256';
    const encoding = 'hex';
    const password = key;
    let keys = [];

    for (let [k, value] of Object.entries(
      encryptedObject,
    )) {
      if (value) {
        keys.push(k);
      }
    }

    const output = cryptoJSON.decrypt(
      encryptedObject,
      password,
      {
        encoding,
        keys,
        algorithm,
      },
    );
    return output;
  }

  // ================================================================= //
  //                          ENCRYPT STRING                           //
  // ================================================================= //
  static encryptString(message, key) {
    const CryptoJS = require('crypto-js');

    // Encrypt
    var ciphertext = CryptoJS.AES.encrypt(message, key);
    return ciphertext;
  }

  // ================================================================= //
  //                          DECRYPT STRING                           //
  // ================================================================= //
  static decryptString(ciphertext, key) {
    const CryptoJS = require('crypto-js');

    // Decrypt
    var bytes = CryptoJS.AES.decrypt(
      ciphertext.toString(),
      key,
    );
    var plaintext = bytes.toString(CryptoJS.enc.Utf8);
    return plaintext;
  }
  // ================================================================= //
  //                      Object ENCRYPTION OTHER WAY                  //
  // ================================================================= //
  static encryption(data, key) {
    const CryptoJS = require('crypto-js');

    // Encrypt
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      key,
    );
    return ciphertext;
  }

  // ================================================================= //
  //                      Object DECRYPTION OTHER WAY                  //
  // ================================================================= //
  static decryption(cipherData, key) {
    var CryptoJS = require('crypto-js');

    // Decrypt
    var bytes = CryptoJS.AES.decrypt(
      cipherData.toString(),
      key,
    );
    var decryptedData = JSON.parse(
      bytes.toString(CryptoJS.enc.Utf8),
    );
    return decryptedData;
  }
    //#endregion
}
