//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { EXCEL_EXTENSION } from '@/shared/excel/excel';
import { EXCEL_TYPE } from '@/shared/excel/excel';

export default {
  name: 'app-importer-form',

  props: ['storePath'],

  computed: {
    errorMessage() {
      return this.$store.getters[
        `${this.storePath}/errorMessage`
      ];
    },

    accept() {
      return `${EXCEL_TYPE}, ${EXCEL_EXTENSION}`;
    },
  },

  methods: {
    onChange(file) {
      if (file) {
        this.$store.dispatch(
          `${this.storePath}/doReadFile`,
          file.raw,
        );
      }
    },
  },
};
