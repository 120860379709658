const en = {
  common: {
    or: 'or',
    cancel: 'Cancel',
    reset: 'Reset',
    save: 'Save',
    submit:'Submit',
    search: 'Search',
    edit: 'Edit',
    remove: 'Remove',
    new: 'New',
    export: 'Export to Excel',
    noDataToExport: 'No data to export',
    import: 'Import',
    discard: 'Discard',
    yes: 'Yes',
    no: 'No',
    pause: 'Pause',
    areYouSure: 'Are you sure?',
    view: 'View',
    destroy: 'Delete',
    mustSelectARow: 'Must select a row',
    confirm: 'Confirm',
    AM:'AM',
    PM:'PM',
    oneOfRunprofCompanies:'One of runprof companies'
  },

  app: {
    title: 'Sayes Website',
  },
  layout:{
    about:'About',
    services:'Services',
    contactUs:'Contact us',
    becomeASayes:'Become a sayes',
    downloadNow:'Download Now'
  },
  about:{
    sayesApp:'Sayes App!',
    parkItEasily:'Park it Easily Now With',
    parkItEasilyDescription:'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna.',
    getStarted:'Get Started',
    about:'About',
    weAre:'we are',
    aboutDesc:'Sayes app is the easiest parking way in Egypt. Customers can park their own vehicles easily by using sayes. They can find the nearest garage with the most cheapie price, best rating, and the most comfortable way.',
    a:'A',
    collection:'Collection',
    ofFeatures:'of features',
    garagesInMap:'Garages in map',
    garagesInMapDesc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet .',
    payment:'Payment',
    paymentDesc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet .',
    notification:'Notification',
    notificationDesc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet .',
    allFunctional:'All Functional ',
    featuresIncluded:'Features Included',
    functionalFeaturesDesc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet .Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet .',
    learnMore:'Learn More',
    downloadTo:'Download to',
    yourPhone:'your phone',
    forty:'40',
    screens:'Screens'
  },
  services:{
    title1:'Park it Easily Now',
    title2:'With Sayes App',
    parkItEasily:'Park it easily',
    parkItEasilyDesc:'Sayes app is where you can find the perfect place to park your vehicle in the easiest and fastest way.',
    findingNearbyGarage:'Finding nearby garage',
    findingNearbyGarageDesc:'Sayes app allows you to choose the nearest garage with the best price and rating nearby you.',
    garagesInfo:'Garages info',
    garagesInfoDesc:'Sayes app can show you full information about each garage such as garage name, price, rating garage photos and other information that can help you make your decision.',
    navigating:'Navigating',
    navigatingDesc:'Sayes app helps you reach your chosen garage by creating the best route to reach your destination as fast as possible.',
    trackingTime:'Tracking time',
    trackingTimeDesc1:'Sayes app calculates for you parking time and approximated quote during parking.',
    trackingTimeDesc2:`So it's easy to know how much you will pay for your parking time.`,
    paymentInfo:'Payment info',
    paymentInfoDesc:'Sayes app creates a final invoice with full details about the entering and exiting time and parking cost. ',
    visaOrCash:'Visa or Cash',
    visaOrCashDesc:'Sayes app allows you to choose the suitable way to pay for your parking by using cash or credit.',
    one:'1.',
    two:'2.',
    three:'3.',
    four:'4.',
    five:'5.',
    six:'6.',
    seven:'7.'
  },
  contactUs:{
    beInTouch:'Be in touch',
    subtitle:'Please fill your contact details below',
    name:'Name',
    namePlaceholder:'Enter your name',
    email:'Email',
    emailPlaceholder:'Enter your email address',
    mobileNum:'Mobile Number',
    mobileNumPlaceholder:'Enter your mobile number',
    notes:'Notes',
    notesPlaceholder:'Any Special Requests'
  },
  downloadNow:{
    title:'Download our app today!',
    subtitle:'We’ll help you achieve your marketing & business goals',
    allRightReserved:'Copyright ©2021 Sayes LLC'
  },
  becomeSayes:{
    becomeASayes:'Become a sayes',
    subtitle:'Please enter the following information to complete your form and click submit',
    name:'Name',
    namePlaceholder:'Enter your name here',
    phone:'Phone',
    phonePlaceholder:'Enter your phone here',
    address:'Address',
    addressPlaceholder:'Enter your address here',
    garageName:'Garage Name',
    garagePlaceholder:'Enter your garage name here',
    availableDate:'Available date',
    selectAvailableDate:'Select available date',
  },
  chat:{
    emailIsRequired:'Email is required',
    invalidEmailAddress:'Invalid Email Address',
    invalidPhoneNumber:'Invalid Phone Number'
  },
  auth :{
    signin:'Sign In',
    signup:'Sign Up',
    enterEmailAndPassword:'Enter your email and password or Sign in via your social accounts',
    dontHaveAnAccount:`Don't have an account yet?`,
    email:'Email',
    password:'Password',
    loginWithFacebook:'Login with Facebook',
    loginWithGoogle:'Login with Google',
    alreadyHaveAnAccount:'Already have an account?',
    next:'Next',
    confirmPassword:'Confirm Password',
    name:'Name',
    oneOfTwo:'1/2',
    twoOfTwo:'2/2',
    confirm:'Confirm',
    confirmYourEmail:'Please confirm your email at',
    toContinue:'to continue.',
    resendEmailVerification:'Resend Email Verification'
  },
  entities: {
    dashboard:{
      dashboard:'Dashboard',
      database:'Database',
      admins:'Admins',
      setting:'Setting',

      realTimeData:'Realtime Data',
      adminPanel:'This Admin panel is the simplest way to control your parking area  using your smartphone only.',
      garages:'Garages',
      users:'Users',
      parkingTransaction:'Parking Transaction',
      totalRevenue:'Total Revenue',
      sinceLastMonth:'Since last month',
      carsAndMotorycles:'Cars and motorcycles',
      onlyCars:'Only Cars',

      id:'ID',
      name:'Name',
      photosId:'Photos ID',
      pricesId:'Prices ID',
      ownerId:'Owner ID',
      availableParking:'Available Parking',
      description:'Description',
      avatar:'Avatar',
      firstName:'First Name',
      lastName:'Last Name',
      phoneNumber:'Phone Number',
      garageId:'Garage ID',
      vehicleId:'Vehicle ID',
      clientId:'Client ID',
      promoCodeId:'Promo Code ID',
      organizerId:'Organizer ID',
      collectorId:'Collector ID',
      parkingTransactionId:'Parking Transaction ID',
      authorizationId:'Authorization ID',
      paymentMethod:'Payment Method',
      totalRate:'Total Rate',
      isClient:'Is Client',
      plateNumber:'Plate Number',
      country:'Country',
      status:'Status',
      emailAddress:'Email Address',
      facebook:'Facebook',
      photoUrl:'Photo Url',
      hour:'Hour',
      halfDay:'HalfDay',
      fullDay:'Full Day',
      monthly:'Monthly',
      isEnable:'Is Enable',
      expiryDate:'Expiry Date',
      code:'Code',
      condition:'Condition',
      sharedUrl:'Shared URL',
      arriveTime:'Arrive Time',
      exitTime:'Exit Time',
      totalPayment:'Total Payment',
      creditCardTransaction:'Credit Card Transaction',
      indebtedness:'Indebtedeness',
      isCollected:'Is Collected',
      date:'Date',
      role:'Role',
      chats:'Chats',
      searchForCollectors:'Search For Collectors',
      typeYourMessageHere:'Type your message here ...',
      web:'Web',
      collector:'Collector',
      manager:'Manager',
      uploadYourAvatar:'Upload Your Avatar',
      save:'Save',
      reset:'Reset',
      email:'Email',
      location:'Location',
      availableParkingArea:'Available Parking Area',
      organizers:'Organizers',
      parkingAreaAvailableInThisGarage:'Parking area Available in this garage',
      viewMore:'View More',
      approve:'Approve',
      reject:'Reject',
      rejected:'Rejected',
      rejectionReason:'Please tell us about your rejection reason',
      typeYourReasonHere:'Type your reason here',
      prices:'Prices',
      createdAt:'Created at',
      garageType:'Garage Type',
      aboutThisGarage:'About this Garage',
      viewLocationOnMap:'view Location on map',
      request:'Request',
      rejectReason:'Reject Reason',
      activity:'Activity',
      totalGarageRevenue:'Total Garage Revenue',
      lastMonth:'Last Month',
      lastThreeMonth:"Last 3 Months",
      accountCreatedAt:'Your account created at ',
      noParking:' No Parking area available in this Garage',
      saveChanges:'Save Changes',
      logout:'Logout',
      signout:'Do you want to sign out?',
      yes:'Yes',
      noThanks:'No,thanks',

      owners:'Owners',
      clients:'Clients',
      photos:'Photos',
      rate:'Rate',
      problems:'Problems',
      vehicles:'Vehicles',
      payment:'Payment',
      invitation:'Invitation',
      promoCode:'Promo-Code',
      parkingHistory:'Parking History',
      favourite:'Favourite',
      garagesIndebtedness:'Garages Indebtedness',
      thisGarageIsFor:'This Garage is for',
      adminForm:'Admin Form',
      garage:'Garage',
      garageRequest:'Garage Request',
      garageRequests:'Garage Requests',



      garagesTable:'Garages Table',
      garagesHeader:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',

      garageOwnersTable:'Garages Owners Table',
      garageOwnersHeader:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',

      organizersTable:'Organizers Table',
      organizersHeader:'Lorem  ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',

      clientsTable:'Clients Table',
      clientsHeader:'Lorem  ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',

      photosTable:'Photos Table',
      photosHeader:'Lorem  ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',

      pricesTable:'Prices Table',
      pricesHeader:'Lorem  ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',

      rateTable:'Rate Table',
      rateHeader:'Lorem  ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',

      complaintsTable:'Complaints Table',
      complaintsHeader:'Lorem  ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',

      vehicleTable:'Vehcile Table',
      vehicleHeader:'Lorem  ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',

      paymentTable:'Payment Table',
      paymentHeader:'Lorem  ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',

      promoCodeTable:'Promo Code Table',
      promoCodeHeader:'Lorem  ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',

      invitationTable:'Invitation Table',
      invitationHeader:'Lorem  ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',

      parkingTransactionTable:'Parking Transaction Table',
      parkingTransactionHeader:'Lorem  ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',

      parkingHistoryTable:'Parking History Table',
      parkingHistoryHeader:'Lorem  ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',

      favouriteTable:'Favourite Table',
      favouriteHeader:'Lorem  ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',

      garageindebtednessTable:'Garage Indebtedness',
      garageindebtednessHeader:'Lorem  ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',

      adminHeader:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      addNewAdmin:'Add New Admin',

      settingHeader:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',

      pendingRequests:'Pending Requests (3)',
      pendingRequestsHeader:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',

      notifications:'Notifications',
      notificationHeader:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',

      profile:'Profile',
      profileHeader:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',

    },
    customer: {
      name: 'customer',
      label: 'Customers',
      menu: 'Customers',
      exporterFileName: 'customer_export',
      list: {
        menu: 'Customers',
        title: 'Customers',
      },
      create: {
        success: 'Customer saved successfully',
      },
      update: {
        success: 'Customer saved successfully',
      },
      destroy: {
        success: 'Customer deleted successfully',
      },
      destroyAll: {
        success: 'Customer(s) deleted successfully',
      },
      edit: {
        title: 'Edit Customer',
      },
      fields: {
        id: 'Id',
        'name': 'Name',
        'birthdateRange': 'Birthdate',
        'birthdate': 'Birthdate',
        'gender': 'Gender',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        'gender': {
          'male': 'Male',
          'female': 'Female',
        },
      },
      new: {
        title: 'New Customer',
      },
      view: {
        title: 'View Customer',
      },
      importer: {
        title: 'Import Customers',
        fileName: 'customer_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    product: {
      name: 'product',
      label: 'Products',
      menu: 'Products',
      exporterFileName: 'product_export',
      list: {
        menu: 'Products',
        title: 'Products',
      },
      create: {
        success: 'Product saved successfully',
      },
      update: {
        success: 'Product saved successfully',
      },
      destroy: {
        success: 'Product deleted successfully',
      },
      destroyAll: {
        success: 'Product(s) deleted successfully',
      },
      edit: {
        title: 'Edit Product',
      },
      fields: {
        id: 'Id',
        'name': 'Name',
        'description': 'Description',
        'unitPriceRange': 'Unit Price',
        'unitPrice': 'Unit Price',
        'photos': 'Photos',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {

      },
      new: {
        title: 'New Product',
      },
      view: {
        title: 'View Product',
      },
      importer: {
        title: 'Import Products',
        fileName: 'product_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    order: {
      name: 'order',
      label: 'Orders',
      menu: 'Orders',
      exporterFileName: 'order_export',
      list: {
        menu: 'Orders',
        title: 'Orders',
      },
      create: {
        success: 'Order saved successfully',
      },
      update: {
        success: 'Order saved successfully',
      },
      destroy: {
        success: 'Order deleted successfully',
      },
      destroyAll: {
        success: 'Order(s) deleted successfully',
      },
      edit: {
        title: 'Edit Order',
      },
      fields: {
        id: 'Id',
        'customer': 'Customer',
        'products': 'Products',
        'employee': 'Employee',
        'delivered': 'Delivered',
        'attachments': 'Attachments',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {

      },
      new: {
        title: 'New Order',
      },
      view: {
        title: 'View Order',
      },
      importer: {
        title: 'Import Orders',
        fileName: 'order_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },
  },

  // auth: {
  //   profile: {
  //     title: 'Edit Profile',
  //     success: 'Profile updated successfully',
  //   },
  //   createAnAccount: 'Create an account',
  //   rememberMe: 'Remember me',
  //   forgotPassword: 'Forgot password',
  //   signin: 'Sign in',
  //   signup: 'Sign up',
  //   signout: 'Sign out',
  //   alreadyHaveAnAccount:
  //     'Already have an account? Sign in.',
  //   signinWithAnotherAccount:
  //     'Sign in with another account',
  //   emailUnverified: {
  //     message: `Please confirm your email at <strong>{0}</strong> to continue.`,
  //     submit: `Resend email verification`,
  //   },
  //   emptyPermissions: {
  //     message: `You have no permissions yet. Wait for the admin to grant you privileges.`,
  //   },
  //   passwordResetEmail: {
  //     message: 'Send password reset email',
  //     error: `Email not recognized`,
  //   },
  //   passwordReset: {
  //     message: 'Reset password',
  //   },
  //   emailAddressVerificationEmail: {
  //     error: `Email not recognized`,
  //   },
  //   verificationEmailSuccess: `Verification email sent successfully`,
  //   passwordResetEmailSuccess: `Password reset email sent successfully`,
  //   passwordResetSuccess: `Password changed successfully`,
  //   verifyEmail: {
  //     success: 'Email successfully verified',
  //     message:
  //       'Just a moment, your email is being verified...',
  //   },
  // },

  roles: {
    owner: {
      label: 'Owner',
      description: 'Full access to all resources',
    },
    editor: {
      label: 'Editor',
      description: 'Edit access to all resources',
    },
    viewer: {
      label: 'Viewer',
      description: 'View access to all resources',
    },
    auditLogViewer: {
      label: 'Audit Log Viewer',
      description: 'Access to view audit logs',
    },
    iamSecurityReviewer: {
      label: 'Security Reviewer',
      description: `Full access to manage users roles`,
    },
    entityEditor: {
      label: 'Entity Editor',
      description: 'Edit access to all entities',
    },
    entityViewer: {
      label: 'Entity Viewer',
      description: 'View access to all entities',
    },
    customerEditor: {
      label: 'Customer Editor',
      description: 'Edit access to Customers',
    },
    customerViewer: {
      label: 'Customer Viewer',
      description: 'View access to Customers',
    },
    productEditor: {
      label: 'Product Editor',
      description: 'Edit access to Products',
    },
    productViewer: {
      label: 'Product Viewer',
      description: 'View access to Products',
    },
    orderEditor: {
      label: 'Order Editor',
      description: 'Edit access to Orders',
    },
    orderViewer: {
      label: 'Order Viewer',
      description: 'View access to Orders',
    },
  },

  iam: {
    title: 'Identity and Access Management',
    menu: 'IAM',
    disable: 'Disable',
    disabled: 'Disabled',
    enabled: 'Enabled',
    enable: 'Enable',
    doEnableSuccess: 'User enabled successfully',
    doDisableSuccess: 'User disabled successfully',
    doDisableAllSuccess: 'User(s) disabled successfully',
    doEnableAllSuccess: 'User(s) enabled successfully',
    doAddSuccess: 'User(s) saved successfully',
    doUpdateSuccess: 'User saved successfully',
    viewBy: 'View By',
    users: {
      name: 'users',
      label: 'Users',
      exporterFileName: 'users_export',
      doRemoveAllSelectedSuccess:
        'Permissions removed successfully',
    },
    roles: {
      label: 'Roles',
      doRemoveAllSelectedSuccess:
        'Permissions removed successfully',
    },
    edit: {
      title: 'Edit User',
    },
    new: {
      title: 'New User(s)',
      titleModal: 'New User',
      emailsHint:
        'Separate multiple email addresses using the comma character.',
    },
    view: {
      title: 'View User',
      activity: 'Activity',
    },
    importer: {
      title: 'Import Users',
      fileName: 'users_import_template',
      hint:
        'Files/Images columns must be the URLs of the files separated by space. Relationships must be the ID of the referenced records separated by space. Roles must be the role ids separated by space.',
    },
    errors: {
      userAlreadyExists:
        'User with this email already exists',
      userNotFound: 'User not found',
      disablingHimself: `You can't disable yourself`,
      revokingOwnPermission: `You can't revoke your own owner permission`,
    },
  },

  user: {
    fields: {
      id: 'Id',
      authenticationUid: 'Authentication Uid',
      avatars: 'Avatar',
      email: 'Email',
      emails: 'Email(s)',
      fullName: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      status: 'Status',
      disabled: 'Disabled',
      phoneNumber: 'Phone Number',
      role: 'Role',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      roleUser: 'Role/User',
      roles: 'Roles',
      createdAtRange: 'Created at',
      password: 'Password',
      rememberMe: 'Remember me',
    },
    enabled: 'Enabled',
    disabled: 'Disabled',
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} is invalid',
    },
  },

  auditLog: {
    menu: 'Audit Logs',
    title: 'Audit Logs',
    exporterFileName: 'audit_log_export',
    entityNamesHint:
      'Separate multiple entities using the comma character.',
    fields: {
      id: 'Id',
      timestampRange: 'Period',
      entityName: 'Entity',
      entityNames: 'Entities',
      entityId: 'Entity ID',
      action: 'Action',
      values: 'Values',
      timestamp: 'Date',
      createdByEmail: 'User Email',
    },
  },
  settings: {
    title: 'Settings',
    menu: 'Settings',
    save: {
      success:
        'Settings saved successfully. The page will reload in {0} seconds for changes to take effect.',
    },
    fields: {
      theme: 'Theme',
    },
    colors: {
      default: 'Default',
      cyan: 'Cyan',
      'geek-blue': 'Geek Blue',
      gold: 'Gold',
      lime: 'Lime',
      magenta: 'Magenta',
      orange: 'Orange',
      'polar-green': 'Polar Green',
      purple: 'Purple',
      red: 'Red',
      volcano: 'Volcano',
      yellow: 'Yellow',
    },
  },
  home: {
    menu: 'Home',
    message: `This page uses fake data for demonstration purposes only. You can edit it at frontend/src/modules/home/components/home-page.vue.`,
    charts: {
      day: 'Day',
      red: 'Red',
      green: 'Green',
      yellow: 'Yellow',
      grey: 'Grey',
      blue: 'Blue',
      orange: 'Orange',
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      customer: 'Customer',
    },
  },
  errors: {
    backToHome: 'Back to home',
    403: `Sorry, you don't have access to this page`,
    404: 'Sorry, the page you visited does not exist',
    500: 'Sorry, the server is reporting an error',
    forbidden: {
      message: 'Forbidden',
    },
    validation: {
      message: 'An error occurred',
    },
    defaultErrorMessage: 'Ops, an error occurred',
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} is invalid',
      required: '${path} is required',
      oneOf:
        '${path} must be one of the following values: ${values}',
      notOneOf:
        '${path} must not be one of the following values: ${values}',
      notType: ({ path, type, value, originalValue }) => {
        return `${path} must be a ${type}`;
      },
    },
    string: {
      length:
        '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches:
        '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected',
    },
    number: {
      min:
        '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer',
      invalid: '${path} must be a number',
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}',
    },
    boolean: {},
    object: {
      noUnknown:
        '${path} field cannot have keys not specified in the object shape',
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max:
        '${path} field must have less than or equal to ${max} items',
    },
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    image: 'You must upload an image',
    size: 'File is too big. Max allowed size is {0}',
    formats: `Invalid format. Must be '{0}'.`,
  },
  importer: {
    line: 'Line',
    status: 'Status',
    pending: 'Pending',
    imported: 'Imported',
    error: 'Error',
    total: `{0} imported, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage:
      'Do not navigate away from this page or import will be stopped.',
    completed: {
      success:
        'Import completed. All rows were successfully imported.',
      someErrors:
        'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.',
    },
    form: {
      downloadTemplate: 'Download the template',
      hint:
        'Click or drag the file to this area to continue',
    },
    list: {
      discardConfirm:
        'Are you sure? Non-imported data will be lost.',
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel:
        'Only excel (.xlsx) files are allowed',
      invalidFileUpload:
        'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported',
    },
  },

  autocomplete: {
    loading: 'Loading...',
  },

  imagesViewer: {
    noImage: 'No image',
  },

  firebaseErrors: {
    'auth/user-disabled': 'Your account is disabled',
    'auth/user-not-found': `Sorry, we don't recognize your credentials`,
    'auth/wrong-password': `Sorry, we don't recognize your credentials`,
    'auth/weak-password': 'This password is too weak',
    'auth/email-already-in-use': 'Email is already in use',
    'auth/invalid-email': 'Please provide a valid email',
    'auth/account-exists-with-different-credential':
      'Email is already in use for a different authentication method.',
    'auth/credential-already-in-use':
      'Credentials are already in use',
  },
};

export default en;
