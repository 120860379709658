//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Fragment } from "vue-fragment";
import { mapActions, mapGetters } from "vuex";

import Logo from "@/modules/layout/components/header-logo.vue";
import ModalMenuPrimary from "@/modules/layout/components/modal-menu-primary.vue";

export default {
  name: "Menu-Modal",
  components: {
    Logo,
    ModalMenuPrimary,
    Fragment,
  },
  data() {
    return {
      items: [
        {
          id: 1,
          title: "LinkedIn",
          link: "https://www.linkedin.com/company/18135051/",
          icon: "fab fa-linkedin-in",
        },
        {
          id: 2,
          title: "Facebook",
          link: "https://www.facebook.com/adveits/",
          icon: "fab fa-facebook-f",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      showMenuModal: "layout/showMenuModal",
    }),
  },
  methods: {
    ...mapActions({
      closeMenuModal: "layout/closeMenuModal",
      toggleMenuModal: "layout/toggleMenuModal",
      variablesNull: "layout/variablesNull",
    }),
    startTransitionModal() {
      if (!this.$refs.backdrop.classList.contains("d-block")) {
        this.$refs.backdrop.classList.add("d-block");
      }

      if (!this.$refs.modal.classList.contains("d-block")) {
        this.$refs.modal.classList.add("d-block");
      }
    },
    startTransitionModalLeave() {
      if (this.$refs.backdrop.classList.contains("d-block")) {
        this.$refs.backdrop.classList.remove("d-block");
      }

      if (this.$refs.modal.classList.contains("d-block")) {
        this.$refs.modal.classList.remove("d-block");
      }
    },
    endTransitionModal() {
      if (!this.$refs.backdrop.classList.contains("show")) {
        this.$refs.backdrop.classList.add("show");
      }

      if (!this.$refs.modal.classList.contains("show")) {
        this.$refs.modal.classList.add("show");
      }

      if (!document.body.classList.contains("modal-open")) {
        document.body.classList.add("modal-open");
      }
    },
    endTransitionModalLeave() {
      if (this.$refs.backdrop.classList.contains("show")) {
        this.$refs.backdrop.classList.remove("show");
      }

      if (this.$refs.modal.classList.contains("show")) {
        this.$refs.modal.classList.remove("show");
      }

      if (document.body.classList.contains("modal-open")) {
        document.body.classList.remove("modal-open");
      }
    },
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.isComposing || e.keyCode === 27) {
        this.closeMenuModal();
      }
    });
  },
  beforeDestroy() {
    if (document.body.classList.contains("modal-open")) {
      document.body.classList.remove("modal-open");
    }

    document.removeEventListener("keydown", (e) => {
      if (e.isComposing || e.keyCode === 27) {
        this.closeMenuModal();
      }
    });

    this.variablesNull();
  },
};
