//
//
//
//
//
//

import {mapActions} from 'vuex'
    export default {
        name: 'MenuModalButton',
        methods: {
            ...mapActions({
                toggleMenuModal:'layout/toggleMenuModal'
            }),
            doToggleMenuModal() {
                this.toggleMenuModal()
            }
        }
    }
