//
//
//
//

import { i18n } from '@/vueI18n';

export default {
  name: 'app-i18n',
  props: ['code', 'args'],
  computed: {
    message: function() {
      return i18n(this.code, ...(this.args || []));
    },
  },
};
