export default {
  namespaced: true,

  state: {
    menuCollapsed: false,
    isMobile: false,
    tab: "about",
    active: "",
    anonymousToken: null,
    showMenuModal: false,
  },

  getters: {
    menuCollapsed: (state) => state.menuCollapsed,
    isMobile: (state) => !!state.isMobile,
    paginationLayout: (state) =>
      state.isMobile
        ? "total, prev, pager, next"
        : "total, sizes, prev, pager, next",
    labelPosition: (state) => (state.isMobile ? "top" : undefined),
    labelWidthForm: (state) => (state.isMobile ? undefined : "180px"),
    labelWidthFilter: (state) => (state.isMobile ? undefined : "120px"),
    tab: (state) => state.tab,
    active: (state) => state.active,
    anonymousToken: (state) => state.anonymousToken,
    showMenuModal: (state) => state.showMenuModal,
  },

  mutations: {
    COLLAPSE_MENU(state) {
      state.menuCollapsed = true;
    },

    TOGGLE_MENU(state) {
      state.menuCollapsed = !state.menuCollapsed;
    },

    RESIZE(state, payload) {
      state.isMobile = payload.width < 576;
    },

    TOGGLE_TAB(state, payload) {
      state.tab = payload;
    },
    SET_ACTIVE(state, payload) {
      state.active = payload;
    },
    SET_ANONYMOUS_TOKEN(state, payload) {
      state.anonymousToken = payload;
    },
    TOGGLE_MENU_MODAL(state) {
      state.showMenuModal = !state.showMenuModal;
    },
    CLOSE_MENU_MODAL(state) {
      state.closeMenuModal = false;
    },
    VARIABLES_NULL(state) {
      state.showMenuModal = false;
      state.showSearchModal = false;
    },
  },

  actions: {
    resize({ commit }, payload) {
      commit("RESIZE", payload);
    },

    toggleMenu({ commit }) {
      commit("TOGGLE_MENU");
    },

    collapseMenu({ commit }) {
      commit("COLLAPSE_MENU");
    },
    navigation({ commit }, payload) {
      commit("TOGGLE_TAB", payload);
    },
    setActive({ commit }, payload) {
      commit("SET_ACTIVE", payload);
    },
    setAnonymousToken({ commit }, payload) {
      commit("SET_ANONYMOUS_TOKEN", payload);
    },
    toggleMenuModal({ commit }) {
      commit("TOGGLE_MENU_MODAL");
    },
    closeMenuModal({ commit }) {
      commit("CLOSE_MENU_MODAL");
    },
    variablesNull({ commit }) {
      commit("VARIABLES_NULL");
    },
  },
};
