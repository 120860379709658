//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex";
import QLocalStorage from "@/shared/local-storage/localStorage";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore"; // for authentication
import i18n from "@/vueI18n";

export default {
  name: "app",

  async created() {
    const promises = [
      new Promise(() => this.doInitFirebase()),
      new Promise(() => this.doInit()),
    ];
    Promise.all(promises).then();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (!this.anonymousToken) {
      const firebaseUser = await firebase.auth().signInAnonymously();
      console.log(firebaseUser);
      // const token = firebaseUser.user.uid;
      // console.log('Token: ',token);
      // this.setAnonymousToken(token);
    }
  },
  // mounted() {
  //   if (localStorage.getItem('language') == "ar") {
  //     import(`quasar/lang/ar`).then((lang) => {
  //       this.$q.lang.set(lang.default);
  //     });
  //   } else {
  //     import(`quasar/lang/en-us`).then((lang) => {
  //       this.$q.lang.set(lang.default);
  //     });
  //   }
  // },
  mounted() {
    if (QLocalStorage.getItem("language") == "ar") {
      import(`quasar/lang/ar`).then((lang) => {
        this.$q.lang.set(lang.default);
      });
    } else {
      import(`quasar/lang/en-us`).then((lang) => {
        this.$q.lang.set(lang.default);
      });
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    ...mapActions({
      doInitFirebase: "auth/doInitFirebase",
      doInit: "auth/doInit",
      resize: "layout/resize",
      setAnonymousToken: "layout/setAnonymousToken",
    }),

    handleResize() {
      this.resize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    },
  },

  computed: {
    ...mapGetters({
      loadingInit: "auth/loadingInit",
      anonymousToken: "layout/anonymousToken",
    }),
    language() {
      return i18n.locale;
    },
  },
  watch: {
    language(newVal) {
      // console.log('lang old value', oldVal);
      // console.log('lang new value', newVal);
      if (newVal == "ar") {
        document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
        this.$q.lang.rtl = true;
      }
      if (newVal == "en") {
        document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
        this.$q.lang.rtl = false;
      }
    },
  },
};
