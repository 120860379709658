//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Header from "@/modules/layout/components/header.vue";
import Footer from "@/modules/layout/components/footer.vue";
import ProductData from "@/shared/json/product-data.json";
import ProductDataAr from "@/shared/json/product-data-ar.json";
import i18n from "@/vueI18n";

export default {
  components: {
    [Header.name]: Header,
    [Footer.name]: Footer,
  },
  mounted() {
    document.body.classList.add("single");
    document.body.classList.add("single-adveits_projects");
    document.body.classList.add("bg-fixed");
    document.body.classList.add("bg-stripes");
  },
  beforeDestroy() {
    document.body.classList.remove("single");
    document.body.classList.remove("single-adveits_projects");
    document.body.classList.add("bg-fixed");
    document.body.classList.add("bg-stripes");
  },
  data() {
    return {};
  },
  computed: {
    language() {
      return i18n.locale;
    },
    items() {
      if (this.language == "en") return ProductData.projectsData;
      return ProductDataAr.projectsData;
    },
    item() {
      var newArray = this.items.filter((el) => {
        return el.id == this.$route.params.id;
      });
      return newArray[0];
    },
    images() {
      var images = [];
      for (const key in this.item.images) {
        images.push(this.item.images[key]);
      }
      return images;
    },
    description() {
      var description = [];
      for (const key in this.item.description) {
        description.push(this.item.description[key]);
      }
      return description;
    },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
  watch: {
    language() {
      document.title = this.i18n("app.title") + " | " + this.item.title;
    },
  },
};
