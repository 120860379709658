import { storeAsync } from '@/app-module';
import i18n from '@/vueI18n';
export default {
  // async beforeRouteEnter(to, from, next) {
  //   if(to.meta.title)
  //   document.title = i18n('app.title')+ ' | ' + to.meta.title
  //   else
  //   document.title = i18n('app.title')
  //   if (!to.meta || !to.meta.unauth) {
  //     next();
  //     return;
  //   }

  //   // next()

  //   await storeAsync().dispatch('auth/doWaitUntilInit');

  //   if (storeAsync().getters['auth/signedIn']) {
  //     next('/');
  //   } else {
  //     next();
  //   }
  // },

  async beforeRouteEnter(to, from, next) {
    await storeAsync().dispatch('auth/doWaitUntilInit');
    let { lang } = to.params
    if (!lang || (lang !== 'ar' && lang !== 'en')){
      lang = localStorage.getItem('language') || 'en'
      i18n.locale = lang
      return next({ path: `/${lang}${to.path}` });
    }
    if(lang !== 'ar' && lang !== 'en') lang = 'en'
    i18n.locale = lang
    localStorage.setItem("language",lang)
    next();
    return;
  },
};
