import config from '@/config';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// import { initializeApp } from 'firebase/app';
// const config = { ... };

// const app = initializeApp(config);

export default function firebaseInit() {
  // const app = initializeApp(config.firebaseConfig);
  const app = firebase.initializeApp(config.firebaseConfig);
  app.auth();
}
